<template>
    <table class="table claimlist">
        <thead>
        <tr>
            <th class="media-only-desktop">id</th>
            <th class="media-only-desktop">Ознакомление</th>
            <th>Дело</th>
            <th class="media-only-desktop">Стадия</th>
            <th>Дедлайн стадии</th>
            <th class="media-only-desktop">Исполнитель</th>
            <th class="media-only-desktop">Пользователь</th>
            <th class="media-only-desktop">Дата ответа</th>
            <th></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="appeal in list" :class="{ 'dl-red' : appeal.stage_dl_datediff < 2 && appeal.stage_id == 10, 'dl-yel': appeal.stage_dl_datediff == 2 && appeal.stage_id == 10, 'dl-green': [8,9,11,13].includes(appeal.stage_id)}">
            <td class="media-only-desktop">{{ appeal.id }}</td>
            <td class="media-only-desktop">
                {{ appeal.isRequest == 1 ? (appeal.isApprove == 1 ? `Да, одобрен до ${appeal.approve_to}`:'Да, не одобрен'):'Нет'}}
            </td>
            <td><router-link :to="`/panel/appeal/${appeal.id}/view`">{{ appeal.title }}</router-link></td>
            <td class="media-only-desktop">{{ appeal.stage_title }}</td>
            <td>{{ appeal.stage_dl }}</td>
            <td class="media-only-desktop">{{ appeal.implementer_name }}</td>
            <td class="media-only-desktop">{{ appeal.user_name }}</td>
            <td class="media-only-desktop">{{ appeal.decision_date }}</td>
            <td class="controls flex-st">
                <router-link :to="`/panel/appeal/${appeal.id}/view`" class="item list"><i
                    class="material-icons">description</i></router-link>
                <router-link :to="`/panel/appeal/${appeal.id}/edit`" class="item edit" v-if="$store.state.isAdmin() || $store.state.isImplementor()"><i
                    class="material-icons">edit</i></router-link>
            </td>
        </tr>
        </tbody>
    </table>
</template>

<script>
export default {
    name: "claimList",
    props:{
        list: Array,
    },
}
</script>

<style scoped lang="scss">
@import "../../assets/scss/variables";
tr {
    &.dl-red {
        background: lighten($cl-red, 30);
    }
    &.dl-yel {
        background: lighten($cl-yellow, 10);
    }
    &.dl-green {
        background: lighten($cl-green, 50);
    }
}
</style>